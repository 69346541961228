import styled from 'styled-components';
import {Plus} from '@styled-icons/boxicons-regular/Plus';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Minus} from '@styled-icons/boxicons-regular/Minus';
import {device} from '../../../components/Common/device'

export const PlusIcon = styled(Plus)`
    color: #ff3c1c;
    width: 25px;
    margin-right: 10px;
    flex-shrink:0;
`;

export const MinusIcon = styled(Minus)`
    color: #ff3c1c;
    width: 25px;
    margin-right: 10px;
    flex-shrink:0;
`;

export const FaqWrapper = styled.section`
    padding:100px 0px;
    position:relative;

    @media ${device.tablet} {
        padding:80px 5px;
    }

`;

export const FaqContainer = styled(Container)`
    z-index:30;
    position:relative;
`;

export const FaqAccordianWrapper = styled.div`
    max-width:900px;
    margin:auto;

    @media ${device.tablet} {
        margin-bottom:40px;
    }
`;
export const AccordianRow = styled.div`
    box-shadow: 0px 0px 16px 0px #e4e4e4;
    margin-bottom: 20px;
    border-radius: 6px;
    overflow: hidden;
    :hover{
        // box-shadow: 0px 0px 16px 0px #adf2cd;
        transition: all .5s;
    }

`;
export const Accordian = styled.div`
    overflow: hidden;
    cursor:pointer;
`;
export const AccordianTitleCol = styled.div`
    display:flex;
    align-items:center;
    background:#fff;
    transition: all .5s;

    h6{
        padding: 8px 10px 8px 15px;
        overflow:hidden;
        margin-bottom:0px;
        flex-grow:1;
    }
`;

export const AccordianContentCol = styled.div`
    padding: 0px 20px 15px 20px;
    ${props => props.isActive &&`

    `}

    ${props => !props.isActive &&`

    `}

    p{
        color: #565759;
        font-size:15px;
        margin-bottom:0px;
        padding: 10px 0px;
    }
    
`;
