import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaqWrapper,FaqContainer,
    FaqAccordianWrapper} from './faq.style'
import AccordianCustom from './AccordianCustom';
import { useStaticQuery, graphql } from 'gatsby';
import ContactForm from "../ContactForm";

  
const Faq =()=>{
    const JSONData  = useStaticQuery(graphql`
        query{
            faqPage1Json {
                FAQ {
                    AccordianCustom{
                        title
                        description
                    }
                }
            }
        }
    `);
    const FaqData = JSONData.faqPage1Json.FAQ;

    return (
        <FaqWrapper>
            <FaqContainer>
                <Row>
                    <Col md="8">
                        <FaqAccordianWrapper>
                            {
                                FaqData.AccordianCustom.map((item,idx) => {
                                return <AccordianCustom 
                                    title={item.title}
                                    description= {item.description} 
                                />
                                })
                            }
                        </FaqAccordianWrapper>
                    </Col>
                    <Col md="4">
                        <ContactForm />
                    </Col>
                </Row>
            </FaqContainer>
        </FaqWrapper>
    );
}
export default Faq;