import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../components/CommonBanner"
import Faq from "../containers/FaqPage1/Faq"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";
import BackgroundImg from '../assets/faq-1-images/banner.jpg';

const FaqPage1 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Frequently Asked Questions"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Faqs"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/faq-1"
        />
        <Faq />
        <Footer />
    </Layout>
)

export default FaqPage1;