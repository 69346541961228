import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const ContactFormSection = styled.section`
    background: #f5f5f5;
    padding: 30px;
`;
export const ContactFormWrapper = styled.div`
    display:flex;

    @media ${device.tablet} {
        display:block;
    }
`;
export const FormLayout = styled.div`

`;

export const FormHeading = styled.h4`

`;

export const ContactForm = styled.div`

`;

export const  Form = styled.form`
    margin: 0;
`;
export const  FormGroup = styled.div`
    margin-bottom:12px;
`;
export const  InputText = styled.input`
    border: none;
    background:#fff;
    font-size: 14px;
    padding: 8px 10px;
    width: 100%;
    border:1px solid #ddd;
    border-radius:4px;
`;
export const MessageTextarea = styled.textarea`
    border: none;
    background:#fff;
    font-size: 14px;
    padding: 10px 10px;
    width: 100%;
    min-height:100px;
    border:1px solid #ddd;
    border-radius:4px;
`;
export const SubmitBtn = styled.button`
    background: #ff3c1c;
    border: 2px solid #ff3c1c;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size:16px;

    :hover{
        background:#fff;
        color:#ff3c1c;
    }
`;

export const TextCenter = styled.div`
    text-align:left;
`;


export const ContactUsSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const ContactUsSpanErr = styled.span`
    color:red;
    font-size:14px;
`;
